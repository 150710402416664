import { Element, ELEMENTS, ElementItem } from "./elements";

export { Element };

export enum TeamAdress {
  PrincipalTeamAddress = "0xAE6d673619A5638ED95B2D491AE4D48F915aC38f",
  SecondTeamAddress = "0xc9541c59F553159F700d82b895005068bD7f5C3D",
}

export interface Donation {
  teamAdress: TeamAdress;
  value: string;
}
export interface Square {
  element: Element;
  createdAt: number;
}

export interface Transaction {
  element: Element;
  createdAt: number;
  action: Action;
  landIndex: number;
}

export enum Action {
  Plant = 0,
  Harvest = 1,
}

export type ActionableItem = ElementItem;

export const ACTIONABLE_ITEMS = ELEMENTS;
