import "./MarketModal.css";

import Big from "big.js";
import React from "react";
import Modal from "react-bootstrap/Modal";

import Salad from "../../images/element/Salad.png";
import alert from "../../images/ui/expression_alerted.png";
import { service } from "../../machine";
import { numberWithBreaks } from "../../utils/number";
import {
  getMarketRate,
  getNextHalvingThreshold,
  getNextMarketRate,
} from "../../utils/supply";
import { Panel } from "./Panel";
import { currency,token,wiki } from '../../../constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ORIGINAL_SUNFLOWER_BUY_PRICE = Big(0.01);

export const MarketModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [totalSupply, setTotalSupply] = React.useState<number>(1);

  React.useEffect(() => {
    const load = async () => {
      if (isOpen)
        await service.machine.context.blockChain.cacheTotalSupply();

      const supply =
        await service.machine.context.blockChain.totalSupply();
      setTotalSupply(supply);
    };

    load();
  }, [isOpen]);

  const currentPrice =
    ORIGINAL_SUNFLOWER_BUY_PRICE / getMarketRate(totalSupply);
  const nextPrice =
    ORIGINAL_SUNFLOWER_BUY_PRICE / getNextMarketRate(totalSupply);
  const nextHalvingThreshold = getNextHalvingThreshold(totalSupply);

  return (
    <Modal centered show={isOpen} onHide={onClose}>
      <Panel>
        <div id="welcome">
          <h1 className="price-header">
            Supply is growing!
            <img src={alert} className="price-alert" />
          </h1>

          <div className="current-price-info-container">
            <p className="current-price-info">
 Hi, my name is Pixi and I'm excited to embark on this great adventure on Catfarm Island. I'm an energetic and creative person who loves to explore new places and meet new people. I'm also a quick learner and always up for a challenge. I'm sure this journey will be full of surprises and I'm looking forward to discovering all that Catfarm Island has to offer. 
I'm sure this will be an unforgettable experience and I can't wait to share it with all of you. Let's have an amazing adventure together!
            </p>
            <p className="current-price-info">
              When the supply is getting nearer to a halvening event you
              will want to ensure you are ready!
            </p>
          </div>

          <div className="current-price-info-container">
            {nextHalvingThreshold ? (
              <p className="current-price-info">
                The next halvening event will occur when total supply
                reaches {numberWithBreaks(nextHalvingThreshold.amount)}{" "}
                tokens
              </p>
            ) : (
              <p className="current-price-info">
                No more halvening events!
              </p>
            )}
          </div>

          <a href={wiki}>
            <h3 className="current-price-supply-demand">
              Read more about our tokenomics here
            </h3>
          </a>

          {totalSupply > 0 ? (
            <>
              <div>
                <h3 className="current-price-header">Current Price</h3>
                <div className="current-price-container ">
                  <img className="element1-price" src={Salad} />
                  <span className="current-price">
                    = {currentPrice} ${token}
                  </span>
                </div>
              </div>

              {nextHalvingThreshold && (
                <div>
                  <h3 className="current-price-header">Upcoming Price</h3>
                  <div className="current-price-container ">
                    <img className="element1-price" src={Salad} />
                    <span className="current-price">
                      = {nextPrice} ${token}
                    </span>
                  </div>
                </div>
              )}

              <div>
                <h3 className="current-price-header">Total Supply</h3>
                <div className="current-price-container ">
                  <span className="current-price">{totalSupply}</span>
                </div>
              </div>
            </>
          ) : (
            <div>Loading...</div>
          )}

          <div></div>
        </div>
      </Panel>
    </Modal>
  );
};
