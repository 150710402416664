import "./UpgradeModal.css";

import { useService } from "@xstate/react";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Eggplant from "../../images/element/Eggplant.png";
import Cucumber from "../../images/element/Cucumber.png";
import Cauliflower from "../../images/element/Cauliflower.png";
import Pumpkin from "../../images/element/Pumpkin.png";
import Tomato from "../../images/element/Tomato.png";
import cancel from "../../images/ui/cancel.png";
import {
  BlockchainEvent,
  BlockchainState,
  Context,
  service,
} from "../../machine";
import { getUpgradePrice } from "../../utils/land";
import { getMarketRate } from "../../utils/supply";
import { Button } from "./Button";
import { Message } from "./Message";
import { Panel } from "./Panel";
import { currency,token } from '../../../constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  farmSize: number;
  balance: number;
}

export const UpgradeModal: React.FC<Props> = ({
  isOpen,
  onClose,
  farmSize,
  balance,
}) => {
  const [machineState, send] = useService<
    Context,
    BlockchainEvent,
    BlockchainState
  >(service);
  const [totalSupply, setTotalSupply] = React.useState<number>(1);
  const [prices, setPrices] = useState({ first: 0, second: 0, third: 0, fourth: 0 });

  React.useEffect(() => {
    const load = async () => {
      const supply = await service.machine.context.blockChain.totalSupply();
      setTotalSupply(supply);

      await service.machine.context.blockChain.cacheUpgradePrices();
      const upgradePrices = service.machine.context.blockChain.getCachedUpgradePrices();
      setPrices({
        first: upgradePrices.first,
        second: upgradePrices.second,
        third: upgradePrices.third,
        fourth: upgradePrices.fourth,
      });
    };

    load();
  }, [isOpen]);

  const onUpgrade = () => {
    send("SAVE", { action: "UPGRADE" });
    onClose();
  };

  const marketRate = getMarketRate(totalSupply);

  const isUnsaved = machineState.context.blockChain.isUnsaved();
  // let levelOnePrice = 0;
  // let levelTwoPrice = 0;
  // let levelThreePrice = 0;
  // let levelFourPrice = 0;

  // levelOnePrice = getUpgradePrice({ farmSize, totalSupply });
  // levelTwoPrice = getUpgradePrice({ farmSize, totalSupply });
  // levelThreePrice = getUpgradePrice({ farmSize, totalSupply });
  // levelFourPrice = getUpgradePrice({ farmSize, totalSupply });

const levelOnePrice = getUpgradePrice({ farmSize, totalSupply, upgradePrices: prices });
const levelTwoPrice = getUpgradePrice({ farmSize, totalSupply, upgradePrices: prices });
const levelThreePrice = getUpgradePrice({ farmSize, totalSupply, upgradePrices: prices });
const levelFourPrice = getUpgradePrice({ farmSize, totalSupply, upgradePrices: prices });


  return (
    <Modal centered show={isOpen} onHide={onClose}>
      <Panel>
        <div id="charity-container">
          <span>Upgrade Farm</span>

          {isUnsaved ? (
            <>
              <div className="upgrade-required">
                <Message>
                  Save your farm first
                  <img src={cancel} className="insufficient-funds-cross" />
                </Message>
              </div>
              <span id="donate-description">
                You must first save your farm to the blockchain before
                attempting to upgrade.{" "}
              </span>
            </>
          ) : (
            <span id="donate-description">
              Upgrade your farm to unlock new plants and harvestable
              fields.
            </span>
          )}

          <div id="charities">
            {farmSize === 5 && (
              <div>
                <span className="charity-description">
                  Upgrade to 8 fields
                </span>
                <div className="upgrade-icons">
                  <span className="charity-description">Unlock:</span>
                  <img src={Eggplant} className="upgrade-element" />
                  <img src={Cucumber} className="upgrade-element" />
                </div>
                <div className="charity-buttons">
                  <span>{`${levelOnePrice}`+ ` $${token}`}</span>
                  <Button
                    disabled={isUnsaved || balance < levelOnePrice}
                    onClick={onUpgrade}
                  >
                    Upgrade
                  </Button>
                </div>
                {balance < levelOnePrice && (
                  <span className="insufficent-upgrade-funds">
                    Insufficent funds
                  </span>
                )}
              </div>
            )}
            {farmSize === 8 && (
              <div>
                <span className="charity-description">
                  Upgrade to 11 fields
                </span>
                <div className="upgrade-icons">
                  <span className="charity-description">Unlock:</span>
                  <img src={Cauliflower} className="upgrade-element" />
                </div>
                <div className="charity-buttons">
                  <span>{`${levelTwoPrice}`+` $${token}`}</span>
                  <Button
                    disabled={
                      isUnsaved || farmSize < 8 || balance < levelTwoPrice
                    }
                    onClick={onUpgrade}
                  >
                    Upgrade
                  </Button>
                </div>
                {balance < levelTwoPrice && (
                  <span className="insufficent-upgrade-funds">
                    Insufficent funds
                  </span>
                )}
              </div>
            )}

            {farmSize === 11 && (
              <div>
                <span className="charity-description">
                  Upgrade to 14 fields
                </span>
                <div className="upgrade-icons">
                  <span className="charity-description">Unlock:</span>
                  <img src={Pumpkin} className="upgrade-element" />
                </div>
                <div className="charity-buttons">
                  <span>{`${levelThreePrice}`+` $${token}`}</span>
                  <Button
                    disabled={
                      isUnsaved ||
                      farmSize < 11 ||
                      balance < levelThreePrice
                    }
                    onClick={onUpgrade}
                  >
                    Upgrade
                  </Button>
                </div>
                {balance < levelThreePrice && (
                  <span className="insufficent-upgrade-funds">
                    Insufficent funds
                  </span>
                )}
              </div>
            )}

            {farmSize === 14 && (
              <div>
                <span className="charity-description">
                  Upgrade to 17 fields
                </span>
                <div className="upgrade-icons">
                  <span className="charity-description">Unlock:</span>
                  <img src={Tomato} className="upgrade-element" />
                </div>
                <div className="charity-buttons">
                  <span>{`${levelFourPrice}`+` $${token}`}</span>
                  <Button
                    disabled={
                      isUnsaved ||
                      farmSize < 14 ||
                      balance < levelFourPrice
                    }
                    onClick={onUpgrade}
                  >
                    Upgrade
                  </Button>
                </div>
                {balance < levelFourPrice && (
                  <span className="insufficent-upgrade-funds">
                    Insufficent funds
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </Panel>
    </Modal>
  );
};

export const UpgradeOverlay = (props) => (
  <div id="tester" {...props}>
    <Message>Upgrade required</Message>
  </div>
);
