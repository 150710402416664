import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import soil from "../../images/land/soil/soil.png";
import { ActionableItem, Element, Square } from "../../types/contract";
import { ElementItem } from "../../types/elements";
import { UpgradeOverlay } from "../ui/UpgradeModal";
import { Field } from "./Field";

interface Props {
  land: Square[];
  balance: number;
  onHarvest: (landIndex: number) => void;
  onPlant: (landIndex: number) => void;
  selectedItem: ActionableItem;
  elements: ElementItem[];
}

export const FourthZone: React.FC<Props> = ({
  elements,
  land,
  balance,
  onHarvest,
  onPlant,
  selectedItem,
}) => {
  const isUnlocked = land.length > 11;

  return (
    <>
      <div className="zone-island-title4">Area4</div>
      <div
        id="twelve-soil"
        className="grass"
        style={{ gridColumn: "14/15", gridRow: "18/19" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[11]}
            onClick={
              land[11].element === Element.None
                ? () => onPlant(11)
                : () => onHarvest(11)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>
      <div
        id="thirteen-soil"
        className="grass"
        style={{ gridColumn: "15/16", gridRow: "18/19" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[12]}
            onClick={
              land[12].element === Element.None
                ? () => onPlant(12)
                : () => onHarvest(12)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>

      <div
        id="fourteen-soil"
        className="grass"
        style={{ gridColumn: "16/17", gridRow: "18/19" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[13]}
            onClick={
              land[13].element === Element.None
                ? () => onPlant(13)
                : () => onHarvest(13)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>

      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="seven-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "14/15", gridRow: "18/19" }}
          />
        </OverlayTrigger>
      )}

      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="eight-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "15/16", gridRow: "18/19" }}
          />
        </OverlayTrigger>
      )}

      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="nine-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "16/17", gridRow: "18/19" }}
          />
        </OverlayTrigger>
      )}

    </>
  );
};
