import "./Field.css";

import React, { useEffect, useState, useCallback } from "react";



import seedling from "../../images/land/soil/seedling.png";
import Salad from "../../images/element/Salad.png";
import Carrot from "../../images/element/Carrot.png";
import Eggplant2 from "../../images/element/Eggplant.png";
import Cucumber from "../../images/element/Cucumber.png";
import Cauliflower from "../../images/element/Cauliflower.png";
import Pumpkin from "../../images/element/Pumpkin.png";
import Tomato from "../../images/element/Tomato.png";
import planted from "../../images/land/soil/planted.png";
import terrain from "../../images/land/soil/soil.png";
import coin from "../../images/ui/icon.png";
import progressAlmost from "../../images/ui/progress/almost.png";
import progressHalf from "../../images/ui/progress/half.png";
import progressQuarter from "../../images/ui/progress/quarter.png";
import progressStart from "../../images/ui/progress/start.png";
import selectBoxBL from "../../images/ui/select-box/selectbox_bl.png";
import selectBoxBR from "../../images/ui/select-box/selectbox_br.png";
import selectBoxTL from "../../images/ui/select-box/selectbox_tl.png";
import selectBoxTR from "../../images/ui/select-box/selectbox_tr.png";
import { ActionableItem, Element, Square } from "../../types/contract";
import { ElementItem, getMarketElements } from "../../types/elements";
import { secondsToString } from "../../utils/time";
import { useService } from "@xstate/react";
import { Context, BlockchainEvent, BlockchainState, service } from "../../machine";
import { getMarketRate } from "../../utils/supply";

interface Props {
  square: Square;
  selectedItem: ActionableItem;
  elements: ElementItem[];
  balance: number;
  onClick: () => void;
}

function getTimeLeft(createdAt: number, totalTime: number) {
  const secondsElapsed = Date.now() / 1000 - createdAt;
  if (secondsElapsed > totalTime) {
    return 0;
  }

  return totalTime - secondsElapsed;
}

export const Field: React.FC<Props> = ({
  square,
  onClick,
  selectedItem,
  balance,
  elements,
}) => {
  const [_, setTimer] = React.useState<number>(0);
  const [harvestPrice, setHarvestPrice] = React.useState<string>(null);
  const [showPrice, setShowPrice] = React.useState(false);
  const [showInsufficientFunds, setShowInsufficientFunds] = React.useState(false);
  const [elementPrices, setElementPrices] = useState<ElementItem[]>([]);
  const [machineState] = useService<Context, BlockchainEvent, BlockchainState>(service);

  useEffect(() => {
    const load = async () => {
      const supply = await service.machine.context.blockChain.totalSupply();
      await service.machine.context.blockChain.cacheElementGains();
      const elementGains = service.machine.context.blockChain.getCachedElementGains();
      // console.log("Element Gains from Blockchain:", elementGains);
      const marketRate = getMarketRate(supply);
      // console.log("Market Rate:", marketRate);
      const prices = getMarketElements({ marketRate, elementGains });
      setElementPrices(prices);
    };

    load();
  }, [machineState]);

  const element = elementPrices.find((item) => item.element === square.element);
  const totalTime = element?.harvestMinutes * 60;

  const click = React.useCallback(() => {
    const element = elementPrices.find((item) => item.element === square.element);
    // Harvest
    if (element) {
      // console.log('Element Found:', element);
      setHarvestPrice(`+${element.sellPrice}`);
    } else {
      // Plant
      const buyFruit = elementPrices.find(
        (item) => item.element === selectedItem.element
      );

      if (buyFruit.buyPrice > balance) {
        setShowInsufficientFunds(true);
        window.setTimeout(() => {
          setShowInsufficientFunds(false);
        }, 500);

        return;
      }

      setHarvestPrice(`-${buyFruit.buyPrice}`);
    }

    setShowPrice(true);

    // Remove harvest price after X seconds
    window.setTimeout(() => {
      setShowPrice(false);
    }, 700);

    onClick();
  }, [balance, onClick, selectedItem, square.element,elementPrices]);

  const setHarvestTime = React.useCallback(() => {
    setTimer((count) => count + 1);
  }, []);

  React.useEffect(() => {
    if (square.element && square.element !== Element.None) {
      setHarvestTime();
      const interval = window.setInterval(setHarvestTime, 1000);
      return () => window.clearInterval(interval);
    }
    /* eslint-disable */
  }, [square,setHarvestTime]);
  /* eslint-enable */

  const Seedling = () => {
    switch (square.element) {
      case Element.first:
      case Element.second:
      case Element.third:
      case Element.fourth:
      case Element.fifth:
      case Element.sixth:
      case Element.seventh:
        return <img src={seedling} className="seedling" />;
      default:
        return null;
    }
  };

  const Plant = () => {
    switch (square.element) {
      case Element.first:
        return <img src={Salad} className="element" />;
      case Element.second:
        return <img src={Carrot} className="element" />;
      case Element.third:
        return <img src={Eggplant2} className="element" />;
      case Element.fourth:
        return <img src={Cucumber} className="element" />;
      case Element.fifth:
        return <img src={Cauliflower} className="element" />;
      case Element.sixth:
        return <img src={Pumpkin} className="element" />;
      case Element.seventh:
        return <img src={Tomato} className="element" />;
      default:
        return null;
    }
  };

  const Progress = () => {
    if (timeLeft > totalTime * (3 / 4)) {
      return <img src={progressStart} className="progress" />;
    }

    if (timeLeft > totalTime * (1 / 2)) {
      return <img src={progressQuarter} className="progress" />;
    }

    if (timeLeft > totalTime * (1 / 4)) {
      return <img src={progressHalf} className="progress" />;
    }

    if (timeLeft > 0) {
      return <img src={progressAlmost} className="progress" />;
    }

    return null;
  };

  const timeLeft = getTimeLeft(square.createdAt, totalTime);

  if (!elementPrices.length) {
    return <div>Loading...</div>;
  }

  const plantingFruit = elementPrices.find((item) => item.element == selectedItem.element);

  return (
    <div className="field" onClick={!timeLeft ? click : undefined}>
      <div className="harvest" style={{ opacity: showPrice ? "1" : "0" }}>
        <span className="harvest-amount">{harvestPrice}</span>
        <img className="harvest-coin" src={coin} />
      </div>
      {square.element === Element.None && (
        <>
          {!showPrice && plantingFruit && (
            <img className="plant-hint" src={plantingFruit.image} />
          )}
          <img src={terrain} className="soil" />
        </>
      )}
      {
        <span
          className="field-no-funds"
          style={{ opacity: showInsufficientFunds ? 1 : 0 }}
        >
          Insufficient funds
        </span>
      }
      {square.element !== Element.None && (
        <>
          <img src={planted} className="planted-soil" />
          {timeLeft && timeLeft > 0 && Seedling()}
          {timeLeft === 0 && Plant()}
          {Progress()}
          {timeLeft && timeLeft > 0 && (
            <span className="progress-text">
              {secondsToString(timeLeft)}
            </span>
          )}
        </>
      )}
      <div className="field-edges">
        <div>
          <img src={selectBoxTL} />
          <img src={selectBoxTR} />
        </div>
        <div>
          <img src={selectBoxBL} />
          <img src={selectBoxBR} />
        </div>
      </div>
    </div>
  );
};
