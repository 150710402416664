import Web3 from "web3";

import Fishtail from "../abis/Fishtail.json";
import QuickSwap from "../abis/QuickSwapRouter.json";
import Token from "../abis/Token.json";
import {
  Donation,
  Element,
  Square,
  TeamAdress,
  Transaction,
} from "./types/contract";
import { getUpgradePrice } from "./utils/land";
import { onboarded } from "./utils/localStorage";

interface Account {
  farm: Square[];
  balance: number;
  id: string;
}

export const MINIMUM_GAS_PRICE = 40;
const SAVE_OFFSET_SECONDS = 5;

export class BlockChain {
  private web3: Web3 | null = null;
  private token: any | null = null;
  private farm: any | null = null;
  private quickswap: any | null = null;
  private account: string | null = null;
  private details: Account = null;
  private events: Transaction[] = [];
  private saveCount = 0;
  private isTrialAccount = false;

  //add mobile
  public isMobile() {
    const MOBILE_DEVICES =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return MOBILE_DEVICES.test(navigator.userAgent);
  }


  private async connectToMatic() {
    try {
      const maticAccounts = await this.web3.eth.getAccounts();
      this.account = maticAccounts[0];

      this.token = new this.web3.eth.Contract(
        Token.abi as any,
        "0xC9dF8aEEaE1Ac0FF056c0c37FC4F1d97033363D5"
      );
      this.farm = new this.web3.eth.Contract(
        Fishtail.abi as any,
        "0x0E7e0bfe101c9c9186Aef806C45f4BBfdbFaC5a0"
      );
    } catch (e) {
      // Timeout, retry
      if (e.code === "-32005") {
        console.error("Retrying...");
        await new Promise((res) => window.setTimeout(res, 3000));
      } else {
        console.error(e);
        throw e;
      }
    }
  }

  public get isConnected() {
    return this.isTrial || !!this.farm;
  }

  public get hasFarm() {
    return this.details && this.details.farm.length > 0;
  }

  public get myFarm() {
    return this.details;
  }

  private async setupWeb3() {
    if ((window as any).ethereum) {
      try {
        // Request account access if needed
        await (window as any).ethereum.enable();
        this.web3 = new Web3((window as any).ethereum);
      } catch (error) {
        // User denied account access...
        console.error(error);
      }
    } else if ((window as any).web3) {
      this.web3 = new Web3((window as any).web3.currentProvider);
    } else {
      throw new Error("NO_WEB3");
    }
  }

  public async initialise(retryCount = 0) {
    this.saveCount = 0;

    try {
      // It is actually quite fast, we won't to simulate slow loading to convey complexity
      await new Promise((res) => window.setTimeout(res, 1000));
      await this.setupWeb3();
      const chainId = await this.web3.eth.getChainId();

      if (chainId === 137 || chainId === 80001 || chainId === 80002) {
        await this.connectToMatic();
        await this.loadFarm();
      } else {
        throw new Error("WRONG_CHAIN");
      }
    } catch (e) {
      // If it is not a known error, keep trying
      if (
        retryCount < 3 &&
        e.message !== "WRONG_CHAIN" &&
        e.message !== "NO_WEB3"
      ) {
        await new Promise((res) => setTimeout(res, 2000));

        return this.initialise(retryCount + 1);
      }
      console.error(e);
      throw e;
    }
  }

  public async loadFarm() {
    const [account] = await Promise.all([this.getAccount()]);
    this.details = account;
    await this.cacheTotalSupply();
  }

  private async waitForFarm(retryCount = 1) {
    const wait = retryCount * 1000;
    await new Promise((res) => setTimeout(res, wait));
    const farm = await this.farm.methods
      .getLand(this.account)
      .call({ from: this.account });

    if (!farm || !farm.length) {
      await this.waitForFarm(retryCount + 1);
    }
  }

  public async createFarm(donation: Donation) {
    const value = this.web3.utils.toWei(donation.value, "ether");

    await new Promise(async (resolve, reject) => {
      const gasPrice = await this.estimate();

      this.farm.methods
        .createFarm(donation.teamAdress)
        .send({
          from: this.account,
          value,
          to: donation.teamAdress,
          gasPrice,
        })
        .on("error", function (error) {
          console.log({ error });
          reject(error);
        })
        .on("transactionHash", function (transactionHash) {
          console.log({ transactionHash });
        })
        .on("receipt", async function (receipt) {
          console.log({ receipt });
          resolve(receipt);
        });
    });

    await this.waitForFarm();

    await this.loadFarm();
  }

  public async save() {
    const blockChain = this;

    if (this.isTrial) {
      throw new Error("TRIAL_MODE");
    }

    // If this is second save, put a buffer between the saves to ensure blockchain state does overlap
    if (this.saveCount > 0) {
      await new Promise((res) =>
        setTimeout(res, 1000 * SAVE_OFFSET_SECONDS)
      );
    } else {
      // First save
      // For each event, subtract 5 seconds to ensure we are not ahead of the Blockchain timestamp
      this.events = this.events.map((event) => ({
        ...event,
        createdAt: event.createdAt - SAVE_OFFSET_SECONDS,
      }));
    }

    await new Promise(async (resolve, reject) => {
      const gasPrice = await this.estimate();

      this.farm.methods
        .sync(this.events)
        .send({ from: this.account, gasPrice })
        .on("error", function (error) {
          console.log({ error });

          // User rejected
          if (error.code === 4001) {
            return resolve(null);
          }

          reject(error);
        })
        .on("transactionHash", function (transactionHash) {
          console.log({ transactionHash });
        })
        .on("receipt", function (receipt) {
          console.log({ receipt });
          blockChain.events = [];
          resolve(receipt);
        });
    });

    onboarded();
    this.saveCount += 1;
  }

  public async estimate(incr = 1) {
    const e = await this.web3.eth.getGasPrice();
    let gasPrice = e ? Number(e) * incr : undefined;
    const minimum = MINIMUM_GAS_PRICE * 1000000000;
    if (!gasPrice || gasPrice < minimum) {
      gasPrice = minimum;
    }
    return gasPrice;
  }

  public async levelUp() {
    if (this.isTrial) {
      throw new Error("TRIAL_MODE");
    }

    await new Promise(async (resolve, reject) => {
      const gasPrice = await this.estimate();

      this.farm.methods
        .levelUp()
        .send({ from: this.account, gasPrice })
        .on("error", function (error) {
          console.log({ error });
          // User rejected
          if (error.code === 4001) {
            return resolve(null);
          }
          reject(error);
        })
        .on("transactionHash", function (transactionHash) {
          console.log({ transactionHash });
        })
        .on("receipt", async function (receipt) {
          console.log({ receipt });
          resolve(receipt);
        });
    });

    // Récupérer et mettre en cache les prix de mise à niveau
  await this.cacheUpgradePrices();
  const prices = this.getCachedUpgradePrices();

  // Vérifiez que les prix sont définis avant de les utiliser
  if (!prices || !prices.first || !prices.second || !prices.third || !prices.fourth) {
    throw new Error("Upgrade prices are not defined");
  }

    const price = getUpgradePrice({
      totalSupply: this.totalSupply(),
      farmSize: this.details.farm.length,
      upgradePrices: prices,
    });

    this.details = {
      ...this.details,
      balance: this.details.balance - price,
      farm: [
        ...this.details.farm,
        { createdAt: 0, element: Element.first },
        { createdAt: 0, element: Element.first },
        { createdAt: 0, element: Element.first },
      ],
    };
  }

  private async getAccount(): Promise<Account> {
    if (!this.web3 || this.isTrial) {
      return {
        farm: [
          {
            createdAt: 0,
            element: Element.None,
          },
          {
            createdAt: 0,
            element: Element.first,
          },
          {
            createdAt: 0,
            element: Element.first,
          },
          {
            createdAt: 0,
            element: Element.first,
          },
          {
            createdAt: 0,
            element: Element.None,
          },
        ],
        balance: 0,
        id: this.account,
      };
    }

    const rawBalance = await this.token.methods
      .balanceOf(this.account)
      .call({ from: this.account });
    const farm = await this.farm.methods
      .getLand(this.account)
      .call({ from: this.account });

    const balance = this.web3.utils.fromWei(rawBalance.toString());
    return {
      balance: Number(balance),
      farm,
      id: this.account,
    };
  }

  /**
   * ALWAYS ENSURE THAT A RESOURCE CONTRACT DOES NOT HAVE A PUBLIC MINT!
   * A resource can only be gained through a "stake"
   */
  public async stake({
    resource,
    amount,
  }: {
    resource: string;
    amount: number;
  }) {
    const blockChain = this;

    if (this.isTrial) {
      throw new Error("TRIAL_MODE");
    }

    const gwei = this.web3.utils.toWei(amount.toString(), "ether");

    await new Promise(async (resolve, reject) => {
      this.farm.methods
        .stake(resource, gwei)
        .send({ from: this.account })
        .on("error", function (error) {
          console.log({ error });
          // User rejected
          if (error.code === 4001) {
            return resolve(null);
          }

          reject(error);
        })
        .on("transactionHash", function (transactionHash) {
          console.log({ transactionHash });
        })
        .on("receipt", function (receipt) {
          console.log({ receipt });
          blockChain.events = [];
          resolve(receipt);
        });
    });

    // TODO fix - Polygon data is stale so use this - We are waiting an extra 20 seconds
    await new Promise((res) => setTimeout(res, 20 * 1000));

    await this.loadFarm();
  }

  public async getMarketConversion(): Promise<number> {
    return await this.farm.methods
      .getMarketPrice(1)
      .call({ from: this.account });
  }

  public getWeb3() {
    return this.web3;
  }

  public addEvent(event: Transaction) {
    this.events = [...this.events, event];
  }

  public isUnsaved() {
    return this.events.length > 0;
  }

  public get isTrial() {
    return this.isTrialAccount;
  }

  public startTrialMode() {
    this.isTrialAccount = true;
  }

  public endTrialMode() {
    this.isTrialAccount = false;
  }

  public lastSaved() {
    if (this.events.length === 0) {
      return null;
    }

    return this.events[0].createdAt;
  }
//Supply
  private cachedTotalSupply = 0;

  public async cacheTotalSupply() {
    if (!this.web3 || !this.token) {
      this.cachedTotalSupply = 0;
    }

    const totalSupply = await this.token.methods
      .totalSupply()
      .call({ from: this.account });

    const supply = this.web3.utils.fromWei(totalSupply);

    this.cachedTotalSupply = Number(supply);
  }

  public totalSupply() {
    return this.cachedTotalSupply;
  }

//Donation
private cachedDonation = 0;

public async cacheTotalDonation() {
  if (!this.web3 || !this.farm) {
    this.cachedDonation = 0;
  }

  const donation = await this.farm.methods
    .getDonationPrice()
    .call({ from: this.account });

  this.cachedDonation = Number(donation) / 10;
}

public totalDonation() {
  return this.cachedDonation;
}
   /* HERE */
  /*BURN*/
  public async changeburnUpgrade(burnUpgradeValue: boolean) {
    await new Promise(async (resolve, reject) => {
      const gasPrice = await this.estimate();

      this.farm.methods
        .changeburnUpgrade(burnUpgradeValue)
        .send({
          from: this.account,
          gasPrice,
        })
        .on("error", function (error) {
          console.log({ error });
          reject(error);
        })
        .on("transactionHash", function (transactionHash) {
          console.log({ transactionHash });
        })
        .on("receipt", async function (receipt) {
          console.log({ receipt });
          resolve(receipt);
        });
    });
  }
  public WhoIsConnected() {
     return this.account;
  }
  public GetOwnerWallet() {
    return TeamAdress.PrincipalTeamAddress;
 }

 /*change 1 upgrade*/
 public async changeFirstUpgradePrice(newPrice: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeFirstUpgradePrice(newPrice)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}
/*change 2 upgrade*/
public async changeSecondUpgradePrice(newPrice: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeSecondUpgradePrice(newPrice)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}
/*change 3 upgrade*/
public async changeThirdUpgradePrice(newPrice: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeThirdUpgradePrice(newPrice)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}
/*change 4 upgrade*/
public async changeFourUpgradePrice(newPrice: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeFourUpgradePrice(newPrice)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

/*change Donation value*/
public async changeDonationValue(newPrice: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeDonationValue(newPrice)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}
/*Changer premiere element prix*/
public async changeFirstElementGain(newGain: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeFirstElementGain(newGain)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

public async changeSecondElementGain(newGain: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeSecondElementGain(newGain)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

public async changeThirdElementGain(newGain: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeThirdElementGain(newGain)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

public async changeFourElementGain(newGain: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeFourElementGain(newGain)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

public async changeFiveElementGain(newGain: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeFiveElementGain(newGain)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

public async changeSixElementGain(newGain: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeSixElementGain(newGain)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

public async changeSevenElementGain(newGain: number) {
  await new Promise(async (resolve, reject) => {
    const gasPrice = await this.estimate();

    this.farm.methods
      .changeSevenElementGain(newGain)
      .send({
        from: this.account,
        gasPrice,
      })
      .on("error", function (error) {
        console.log({ error });
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log({ transactionHash });
      })
      .on("receipt", async function (receipt) {
        console.log({ receipt });
        resolve(receipt);
      });
  });
}

private cachedUpgradePricesData: { first: number; second: number; third: number; fourth: number } = { first: 0, second: 0, third: 0, fourth: 0 };

public async cacheUpgradePrices() {
  if (!this.web3 || !this.farm) {
    this.cachedUpgradePricesData = { first: 0, second: 0, third: 0, fourth: 0 };
    return;
  }

  const prices = await this.getUpgradePrices();
  this.cachedUpgradePricesData = {
    first: Number(prices.first),
    second: Number(prices.second),
    third: Number(prices.third),
    fourth: Number(prices.fourth),
  };
}

public getCachedUpgradePrices() {
  return this.cachedUpgradePricesData;
}

/* Fetch upgrade prices */
public async getUpgradePrices(): Promise<{ first: number; second: number; third: number; fourth: number }> {
  const prices = await this.farm.methods.getUpgradePrices().call({ from: this.account });
  return {
    first: prices.first,
    second: prices.second,
    third: prices.third,
    fourth: prices.fourth
  };
}




/* Fetch element gains */

// Ajouter cette partie dans la classe BlockChain

private cachedElementGains: { first: number; second: number; third: number; fourth: number; fifth: number; sixth: number; seventh: number } = {
  first: 0,
  second: 0,
  third: 0,
  fourth: 0,
  fifth: 0,
  sixth: 0,
  seventh: 0,
};

public async cacheElementGains() {
  if (!this.web3 || !this.farm) {
    this.cachedElementGains = { first: 0, second: 0, third: 0, fourth: 0, fifth: 0, sixth: 0, seventh: 0 };
    return;
  }

  const gains = await this.getElementGains();
  this.cachedElementGains = {
    first: Number(gains.first),
    second: Number(gains.second),
    third: Number(gains.third),
    fourth: Number(gains.fourth),
    fifth: Number(gains.fifth),
    sixth: Number(gains.sixth),
    seventh: Number(gains.seventh),
  };
}

public getCachedElementGains() {
  return this.cachedElementGains;
}

public async getElementGains(): Promise<{ first: number; second: number; third: number; fourth: number; fifth: number; sixth: number; seventh: number }> {
  const gains = await this.farm.methods.getElementGains().call({ from: this.account });
  return {
    first: gains.first,
    second: gains.second,
    third: gains.third,
    fourth: gains.fourth,
    fifth: gains.fifth,
    sixth: gains.sixth,
    seventh: gains.seventh
  };
}



 /*END HERE */

  public async getTeamDonationWalletsBalances() {
    const firstTeamWallet = this.web3.eth.getBalance(
      TeamAdress.PrincipalTeamAddress
    );

    const SecondTeamWallet = this.web3.eth.getBalance(
      TeamAdress.SecondTeamAddress
    );

    const [firstTeamWalletBalance, secondTeamWalletBalance] =
      await Promise.all([firstTeamWallet, SecondTeamWallet]);

    return {
      firstTeamWalletBalance: this.web3.utils.fromWei(
        firstTeamWalletBalance,
        "ether"
      ),
      secondTeamWalletBalance: this.web3.utils.fromWei(
        secondTeamWalletBalance,
        "ether"
      ),
    };
  }

  // Used when a player did not save in time
  public offsetTime() {
    const latestTime = this.events[this.events.length - 1];
    const now = Math.floor(Date.now() / 1000);
    const difference = now - latestTime.createdAt;

    // For each event, add the time
    this.events = this.events.map((event) => ({
      ...event,
      createdAt: event.createdAt + difference,
    }));
  }

  public resetFarm() {
    this.events = [];
  }

  public async getReward() {
    try {
      const reward = await this.farm.methods
        .myReward()
        .call({ from: this.account });

      if (!reward) {
        return 0;
      }

      const converted = this.web3.utils.fromWei(reward.toString());

      return Number(converted);
    } catch (e) {
      // No reward ready
      return null;
    }
  }

  public async receiveReward() {
    const reward = await this.getReward();

    await new Promise(async (resolve, reject) => {
      const gasPrice = await this.estimate(2);

      this.farm.methods
        .receiveReward()
        .send({ from: this.account, gasPrice })
        .on("error", function (error) {
          console.log({ error });
          // User rejected
          if (error.code === 4001) {
            return resolve(null);
          }

          reject(error);
        })
        .on("transactionHash", function (transactionHash) {
          console.log({ transactionHash });
        })
        .on("receipt", function (receipt) {
          console.log({ receipt });
          resolve(receipt);
        });
    });

    this.details = {
      ...this.details,
      balance: this.details.balance + reward,
    };
  }

  // Sunflower Tokens -> MATIC
  public async quickswapRate() {
    const base = 10000000000;
    const rate = await this.quickswap.methods
      .getAmountsIn(base, [
        "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        "0xdf9B4b57865B403e08c85568442f95c26b7896b0",
      ])
      .call({ from: this.account });

    return Number(rate[0]) / Number(rate[1]);
  }

  public async approve(address: string, amount: number) {
    const alreadyApproved = await this.token.methods
      .allowance(this.account, address)
      .call({ from: this.account });

    const wei = this.web3.utils.toWei(amount.toString(), "ether");

    if (Number(alreadyApproved) >= Number(wei)) {
      return true;
    }

    return new Promise(async (resolve, reject) => {
      const gasPrice = await this.estimate(2);

      try {
        this.token.methods
          .approve(address, wei)
          .send({ from: this.account, gasPrice })
          .on("error", function (error) {
            console.log({ error });
            reject(error);
          })
          .on("transactionHash", function (transactionHash) {
            console.log({ transactionHash });
          })
          .on("receipt", function (receipt) {
            console.log({ receipt });
            resolve(receipt);
          });
      } catch (e) {
        reject(e);
      }
    });
  }
}
