import "./MarketModal.css";

import React from "react";
import Modal from "react-bootstrap/Modal";

import alert from "../../images/ui/expression_alerted.png";
import { Panel } from "./Panel";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const EventModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal centered show={isOpen} onHide={onClose}>
      <Panel>
        <div id="welcome">
          <h1 className="price-header">
            NEXT MAP ANNOUNCEMENT
            {/* <img src={alert} className="price-alert" /> */}
          </h1>

          <div className="current-price-info-container">
            <p className="current-price-info"></p>
              HEYY ! my name is bryan, i'm not in the kitchen but i'm here to deliver some good news ! <br />
              <br />
              <h3>
                <b> NEW MAP RELEASE ? </b>{" "}
              </h3>
              <p>
              <i>
                a date will be announced soon
              </i>
              <br />
              <br />
              New map , new token, same actions !
              <br />
              {/* <br /> */}
              {/* The temporary changes made to the games are as follows: */}
              {/* <br /> */}
              <br />
              <br /> Join the discord to avoid the surprises that await you
              <br />
              {/* <p className={"blue-text"}>
                ***** The donation for creating an account is now{" "}
                <b>1.9</b> MATIC instead of 2.5. <br /> <br />
                ***** The 4 hour element becomes <b>more profitable</b> (as
                much as the 8 hour element) <br /> <br />
              </p> */}
              Enjoy !!
              {/* Various events will take place in the days to come... such as
              a drop in the price of creating an account, upgrading a house
              or an increase in the gain of this or that element for a period
              of time...
              <br />
              <br /> You will have to be aware of this.
              <br /> Join the discord to avoid the surprises that await you
              !*/}
            </p>
          </div>
        </div>
      </Panel>
    </Modal>
  );
};
