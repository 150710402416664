import React from "react";

import { ActionableItem, Element, Square } from "../../types/contract";
import { ElementItem } from "../../types/elements";
import { Field } from "./Field";

interface Props {
  land: Square[];
  balance: number;
  onHarvest: (landIndex: number) => void;
  onPlant: (landIndex: number) => void;
  selectedItem: ActionableItem;
  elements: ElementItem[];
}

export const FirstZone: React.FC<Props> = ({
  elements,
  land,
  balance,
  onHarvest,
  onPlant,
  selectedItem,
}) => {
  const [showWatering, setShowWatering] = React.useState(false);
  const hasRendered = React.useRef(false);
  React.useEffect(() => {
    if (land[0].element === Element.None) {
      setShowWatering(false);
    }

    // Only show it on first load
    if (!hasRendered.current && land[0].element !== Element.None) {
      setShowWatering(true);
    }

    if (balance) {
      hasRendered.current = true;
    }
  }, [land, balance]);

  return (
    <>     
      <div className="zone-island-title">Area1</div>
      <div
        id="second-soil"
        className="grass"
        style={{ gridColumn: "12/13", gridRow: "22/23" }}
      >
        <Field
          elements={elements}
          balance={balance}
          selectedItem={selectedItem}
          square={land[4]}
          onClick={
            land[4].element === Element.None
              ? () => onPlant(4)
              : () => onHarvest(4)
          }
        />
      </div>

      <div
        id="first-soil"
        className="grass"
        style={{ gridColumn: "13/14", gridRow: "22/23" }}
      >
        <Field
          elements={elements}
          balance={balance}
          selectedItem={selectedItem}
          square={land[2]}
          onClick={
            land[2].element === Element.None
              ? () => onPlant(2)
              : () => onHarvest(2)
          }
        />
      </div>

      <div
        id="three-soil"
        className="grass"
        style={{ gridColumn: "14/15", gridRow: "22/23" }}
      >
        <Field
          elements={elements}
          balance={balance}
          selectedItem={selectedItem}
          square={land[0]}
          onClick={
            land[0].element === Element.None
              ? () => onPlant(0)
              : () => onHarvest(0)
          }
        />
      </div>

      <div
        id="four-soil"
        className="grass"
        style={{ gridColumn: "12/13", gridRow: "21/22" }}
      >
        {/* {<img id="watering" src={watering} />} */}
        <Field
          elements={elements}
          balance={balance}
          selectedItem={selectedItem}
          square={land[3]}
          onClick={
            land[3].element === Element.None
              ? () => onPlant(3)
              : () => onHarvest(3)
          }
        />
      </div>

      <div
        id="five-soil"
        className="grass"
        style={{ gridColumn: "13/14", gridRow: "21/22" }}
      >
        <Field
          elements={elements}
          balance={balance}
          selectedItem={selectedItem}
          square={land[1]}
          onClick={
            land[1].element === Element.None
              ? () => onPlant(1)
              : () => onHarvest(1)
          }
        />
      </div>
    </>
  );
};
