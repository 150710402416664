import { getMarketRate } from "./supply";

interface UpgradePriceOptions {
  farmSize: number;
  totalSupply: number;
  upgradePrices: { first: number; second: number; third: number; fourth: number };
}

export function getUpgradePrice({
  farmSize,
  totalSupply,
  upgradePrices,
}: UpgradePriceOptions) {
  const marketRate = getMarketRate(totalSupply);

  if (farmSize <= 5) {
    return upgradePrices.first / marketRate;
  }

  if (farmSize <= 8) {
    return upgradePrices.second / marketRate;
  }

  if (farmSize <= 11) {
    return upgradePrices.fourth / marketRate;
  }

  return upgradePrices.third / marketRate;
}
