import React from 'react'
import { Panel } from '../ui/Panel'

import goblin from "../../images/characters/goblin_carry.gif";


export const Creating: React.FC = () => (
    <Panel>
        <div id="saving">
            Creating...

            <h6>Miners are working hard to create your farm on the blockchain.</h6>

            <div id='mining-animation'>
                <img id='crafting-gif' src={goblin} />
            </div>

            <span>Increase the gas price for faster transactions</span>
        </div>
    </Panel>
)
