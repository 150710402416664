import "./Board.css";

import Big from "big.js";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import arrow from "../../images/ui/arrow_right.png";
import cancel from "../../images/ui/cancel.png";
import disc from "../../images/ui/disc.png";
import alert from "../../images/ui/expression_alerted.png";
import coin from "../../images/ui/icon.png";
import stopwatch from "../../images/ui/stopwatch.png";
import { ActionableItem } from "../../types/contract";
import { ElementItem, getMarketElements, ElementPriceOptions } from "../../types/elements";
import { secondsToString } from "../../utils/time";
import { Items } from "../ui/Items";
import { Message } from "../ui/Message";
import { Panel } from "../ui/Panel";
import { useService } from "@xstate/react";
import { Context, BlockchainEvent, BlockchainState, service } from "../../machine";
import { getMarketRate } from "../../utils/supply";

interface Props {
  selectedItem: ActionableItem;
  onSelectItem: (item: ActionableItem) => void;
  balance: number;
  land: any[];
  elements: ElementItem[];
}
export const Board: React.FC<Props> = ({
  balance,
  land,
  onSelectItem,
  selectedItem,
  elements,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [machineState] = useService<Context, BlockchainEvent, BlockchainState>(service);
  const [totalSupply, setTotalSupply] = useState<number>(1);
  const [elementPrices, setElementPrices] = useState<ElementItem[]>(elements);
  

  useEffect(() => {
    const load = async () => {
      const supply = await service.machine.context.blockChain.totalSupply();
      setTotalSupply(supply);

      await service.machine.context.blockChain.cacheElementGains();
      const elementGains = service.machine.context.blockChain.getCachedElementGains();

      const marketRate = getMarketRate(supply);
      const prices = getMarketElements({ marketRate, elementGains });
      setElementPrices(prices);
    };

    load();
  }, []);

  const selectFruit = (element: ElementItem) => {
    setShowModal(false);
    onSelectItem(element);
  };

  const items = [];
  let needsUpgrade = false;
  let needsMoreMoney = false;
  elementPrices.forEach((element) => {
    const buyPrice = Big(element.buyPrice).toNumber();
    const sellPrice = Big(element.sellPrice).toNumber();

    if (!needsUpgrade && element.landRequired > land.length) {
      needsUpgrade = true;
    }

    if (!needsMoreMoney && element.buyPrice > balance) {
      needsMoreMoney = true;
    }

    const isLocked = needsUpgrade || needsMoreMoney;

    items.push(
      <div key={element.name} className={isLocked ? "locked item" : "item"}>
        <div
          className={
            selectedItem.element === element.element ? "selected icon" : "icon"
          }
          onClick={!isLocked ? () => selectFruit(element) : undefined}
        >
          <div className="image">
            <img src={element.image} />
          </div>
        </div>
        <div className="element-details">
          <div>
            <span className="title">{element.name}</span>

            <div className="element-time">
              <img src={stopwatch} />
              <span>{secondsToString(element.harvestMinutes * 60)}</span>
            </div>
          </div>
          <div className="element-breakdown">
            <div className="price">
              <span className="price-label">Farming</span>
              <img src={coin} />
              <span>{buyPrice}</span>
            </div>
            <div className="element-arrows">
              <img src={arrow} />
              <img src={arrow} />
              <img src={arrow} />
            </div>
            <div className="price">
              <span className="price-label">Harvest</span>
              <img src={coin} />
              <span>{sellPrice}</span>
            </div>
          </div>
        </div>
      </div>
    );

    if (needsUpgrade) {
      items.push(
        <div className="upgrade-required">
          <Message>
            Upgrade Required
            <img src={alert} className="insufficient-funds-alert" />
          </Message>
        </div>
      );
    } else if (needsMoreMoney) {
      items.push(
        <div className="upgrade-required">
          <Message>
            Insufficient funds
            <img src={cancel} className="insufficient-funds-cross" />
          </Message>
        </div>
      );
    }
  });

  return (
    <>
      <div id="basket" onClick={() => setShowModal(true)}>
        <img className="basket-element" src={disc} />
        <img className="selected-element" src={selectedItem.image} />
        <Message>Change</Message>
      </div>
      <Modal
        show={showModal}
        dialogClassName="element-board-modal"
        centered
        onHide={() => setShowModal(false)}
      >
        <div className="board">
          <Panel hasTabs>
            <Items
              balance={balance}
              elements={elements}
              selectedItem={selectedItem}
              onSelectItem={onSelectItem}
              land={land}
              onClose={() => setShowModal(false)}
            />
          </Panel>
        </div>
      </Modal>
    </>
  );
};
