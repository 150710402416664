import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import soil from "../../images/land/soil/soil.png";
import { ActionableItem, Element, Square } from "../../types/contract";
import { ElementItem } from "../../types/elements";
import { UpgradeOverlay } from "../ui/UpgradeModal";
import { Field } from "./Field";

interface Props {
  land: Square[];
  balance: number;
  onHarvest: (landIndex: number) => void;
  onPlant: (landIndex: number) => void;
  selectedItem: ActionableItem;
  elements: ElementItem[];
}

export const FifthZone: React.FC<Props> = ({
  elements,
  land,
  balance,
  onHarvest,
  onPlant,
  selectedItem,
}) => {
  const isUnlocked = land.length > 14;

  return (
    <>
      <div className="zone-island-title5">Area5</div>

      <div
        id="fifteen-soil"
        className="grass"
        style={{ gridColumn: "17/18", gridRow: "16/17" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[15]}
            onClick={
              land[15].element === Element.None
                ? () => onPlant(15)
                : () => onHarvest(15)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>
      <div
        id="sixteen-soil"
        className="grass"
        style={{ gridColumn: "18/19", gridRow: "16/17" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[16]}
            onClick={
              land[16].element === Element.None
                ? () => onPlant(16)
                : () => onHarvest(16)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>

      <div
        id="seventeen-soil"
        className="grass"
        style={{ gridColumn: "19/20", gridRow: "16/17" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[14]}
            onClick={
              land[14].element === Element.None
                ? () => onPlant(14)
                : () => onHarvest(14)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>

      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="ten-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "17/18", gridRow: "16/17" }}
          />
        </OverlayTrigger>
      )}
      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="eleven-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "18/19", gridRow: "16/17" }}
          />
        </OverlayTrigger>
      )}
      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="twelve-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "19/20", gridRow: "16/17" }}
          />
        </OverlayTrigger>
      )}
    </>
  );
};
