import "./Land.css";

import React from "react";


import map from "../../images/land/map.png";
import { ActionableItem, Square } from "../../types/contract";
import { ElementItem } from "../../types/elements";
import { Barn } from "./Barn";
import { Decoration } from "./Decoration";
import { FifthZone } from "./FifthZone";
import { FirstZone } from "./FirstZone";
import { FourthZone } from "./FourthZone";
import { Market } from "./Market";
import { NewMapMan } from "./NewMapMan";
import { SecondZone } from "./SecondZone";
import { ThirdZone } from "./ThirdZone";
import right_seagul from "../../images/characters/flight-seagul-right.gif";
import left_seagul from "../../images/characters/flight-seagul-left.gif";
import cloud from "../../images/characters/cloud.png";


interface Props {
  land: Square[];
  balance: number;
  onHarvest: (landIndex: number) => void;
  onPlant: (landIndex: number) => void;
  selectedItem: ActionableItem;
  elements: ElementItem[];
  account?: string;
}

const columns = Array(60).fill(null);
const rows = Array(20).fill(null);

// based on the amount of fields, determine the level
const landToLevel = {
  5: 1,
  8: 2,
  11: 3,
  14: 4,
  17: 5,
};

export const Land: React.FC<Props> = ({
  elements,
  land,
  balance,
  onHarvest,
  onPlant,
  selectedItem,
  account,
}) => {
  const level = landToLevel[land.length];

  return (
    <>
      <div id="water-background">
        
        <div className="farm" >
        <img className="Fond" src={map} alt="" />
          <FirstZone
            elements={elements}
            selectedItem={selectedItem}
            land={land}
            balance={balance}
            onHarvest={onHarvest}
            onPlant={onPlant}
          />
          <SecondZone
            elements={elements}
            selectedItem={selectedItem}
            land={land}
            balance={balance}
            onHarvest={onHarvest}
            onPlant={onPlant}
          />

          <ThirdZone
            elements={elements}
            selectedItem={selectedItem}
            land={land}
            balance={balance}
            onHarvest={onHarvest}
            onPlant={onPlant}
          />

          <FourthZone
            elements={elements}
            selectedItem={selectedItem}
            land={land}
            balance={balance}
            onHarvest={onHarvest}
            onPlant={onPlant}
          />

          <FifthZone
            elements={elements}
            selectedItem={selectedItem}
            land={land}
            balance={balance}
            onHarvest={onHarvest}
            onPlant={onPlant}
          />       
          <Barn farmSize={land.length} balance={balance} />
          <Decoration />
          <Market />
          <NewMapMan />

             <img
          id="flying-seagul-to-left-1"
          className="flying-seagul flying-seagul-to-left"
          src={left_seagul}
          />

          <img
            id="flying-seagul-to-right-3"
            className="flying-seagul flying-seagul-to-right"
            src={right_seagul}
          />
          <img
            id="flying-seagul-to-right-2"
            className="flying-seagul flying-seagul-to-right"
            src={right_seagul}
          />
           <img
            id="cloud-to-left-1"
            className="moving-cloud cloud-to-left"
            src={cloud}
          />

           <img
            id="cloud-to-left-2"
            className="moving-cloud cloud-to-left"
            src={cloud}
          />


          
        </div>
      </div>
    </>
  );
};
