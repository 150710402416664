import { useService } from "@xstate/react";
import React from "react";
import ReactTour, { ReactourStep } from "reactour";

import {
  BlockchainEvent,
  BlockchainState,
  Context,
  OnboardingStates,
  service,
} from "../../machine";
import { Button } from "../ui/Button";

const steps: ReactourStep[] = [
  {
    selector: "#first-soil",
    content:
      "How you can take this ! Click on.",
  },
  {
    selector: "#balance",
    content: ({ goTo }) => (
      <div>
        You received $$$ Element land token for the harvest
        <br />
        <div id="tour-button">
          <Button onClick={() => service.send("NEXT")}>Next</Button>
        </div>
      </div>
    ),
  },
  {
    selector: "#first-soil",
    content: "Now you can cast this again!",
  },
  {
    selector: "#save-button",
    stepInteraction: false,
    content: () => (
      <div>
        Don't forget to save your farm within 25 minutes of your first
        action. Once you save you can close the browser and come back later
        to harvest your fields.
        <br />
        <div id="tour-button">
          <Button
            onClick={() => {
              service.send("NEXT");
            }}
          >
            Next
          </Button>
        </div>
      </div>
    ),
  },
  {
    selector: "#basket",
    stepInteraction: false,
    content: () => (
      <div>
        You can buy different types of vegetable here. Some are more expensive
        but yield greater rewards !
        <br />
        <div id="tour-button">
          <Button
            onClick={() => {
              service.send("FINISH");
              window.location.reload();
            }}
          >
            Done
          </Button>
        </div>
      </div>
    ),
  },
];

const STEP_MACHINE: Record<OnboardingStates, number> = {
  harvesting: 0,
  token: 1,
  planting: 2,
  saving: 3,
  market: 4,
};

export const Tuto: React.FC = () => {
  const [machineState, send] = useService<
    Context,
    BlockchainEvent,
    BlockchainState
  >(service);

  const step =
    STEP_MACHINE[
      (machineState.value as any).onboarding as OnboardingStates
    ] || 0;

  const onFinish = () => {
    send("CLOSE");
  };

  return (
    <>
      <ReactTour
        steps={steps}
        isOpen={machineState.matches("onboarding")}
        onRequestClose={onFinish}
        goToStep={step}
        update={step.toString()}
        showButtons={false}
        rounded={5}
        disableDotsNavigation
        className="farm-tour"
        showNumber={false}
        accentColor="#e6a873"
      />
    </>
  );
};

export default Tuto;
