import Big from "big.js";

import Salad from "../images/element/Salad.png";
import Carrot from "../images/element/Carrot.png";
import Eggplant from "../images/element/Eggplant.png";
import Cucumber from "../images/element/Cucumber.png";
import Cauliflower from "../images/element/Cauliflower.png";
import Pumpkin from "../images/element/Pumpkin.png";
import Tomato from "../images/element/Tomato.png";

export enum Element {
  None = "0",
  first = "1",
  second = "2",
  third = "3",
  fourth = "4",
  fifth = "5",
  sixth = "6",
  seventh = "7",
}

export interface ElementItem {
  element: Element;
  name: string;
  image: string;
  buyPrice: number;
  sellPrice: number;
  landRequired: number;
  harvestMinutes: number;
}

export interface ElementPriceOptions {
  marketRate: number;
  elementGains: { first: number; second: number; third: number; fourth: number; fifth: number; sixth: number; seventh: number };
}

export const ELEMENTS: ElementItem[] = [
  {
    element: Element.first,
    name: "Salad",
    image: Salad,
    buyPrice: 0.01,
    sellPrice: 0.0175,
    landRequired: 5,
    harvestMinutes: 1,
  },
  {
    element: Element.second,
    name: "Carrot",
    image: Carrot,
    buyPrice: 0.1,
    sellPrice: 0.1375,
    landRequired: 5,
    harvestMinutes: 5,
  },
  {
    element: Element.third,
    name: "Eggplant",
    image: Eggplant,
    buyPrice: 0.4,
    sellPrice: 0.88,
    landRequired: 8,
    harvestMinutes: 60,
  },
  {
    element: Element.fourth,
    name: "Cucumber",
    image: Cucumber,
    buyPrice: 1,
    sellPrice: 2.7,
    landRequired: 8,
    harvestMinutes: 4 * 60,
  },
  {
    element: Element.fifth,
    name: "Cauliflower",
    image: Cauliflower,
    buyPrice: 4,
    sellPrice: 8,
    landRequired: 11,
    harvestMinutes: 8 * 60,
  },
  {
    element: Element.sixth,
    name: "Pumpkin",
    image: Pumpkin,
    buyPrice: 10,
    sellPrice: 21,
    landRequired: 14,
    harvestMinutes: 24 * 60,
  },
  {
    element: Element.seventh,
    name: "Tomato",
    image: Tomato,
    buyPrice: 50,
    sellPrice: 90,
    landRequired: 17,
    harvestMinutes: 3 * 24 * 60,
  },
];

export function getElement(element: Element) {
  return ELEMENTS.find((item) => item.element === element);
}

// Apply the market rate against to get the current buy and sell prices
// export function getMarketElements(marketRate: number) {
//   return ELEMENTS.map((element) => ({
//     ...element,
//     buyPrice: Big(element.buyPrice).div(marketRate).toNumber(),
//     sellPrice: Big(element.sellPrice).div(marketRate).toNumber(),
//   }));
// }

// Obtenez les prix des éléments en fonction des gains récupérés
export function getMarketElements({ marketRate, elementGains }: ElementPriceOptions): ElementItem[] {
  if (!elementGains) {
    throw new Error("Element gains are not defined");
  }
  // console.log('Market Rate:', marketRate);
  // console.log('Element Gains:', elementGains);

  return ELEMENTS.map((element) => {
    let sellPrice;
    switch (element.element) {
      case Element.first:
        sellPrice = elementGains.first /10000;
        break;
      case Element.second:
        sellPrice = elementGains.second / 10000;
        break;
      case Element.third:
        sellPrice = elementGains.third / 100;
        break;
      case Element.fourth:
        sellPrice = elementGains.fourth / 10;
        break;
      case Element.fifth:
        sellPrice = elementGains.fifth;
        break;
      case Element.sixth:
        sellPrice = elementGains.sixth;
        break;
      case Element.seventh:
        sellPrice = elementGains.seventh;
        break;
      default:
        sellPrice = element.sellPrice;
    }
    return {
      ...element,
      buyPrice: Big(element.buyPrice).div(marketRate).toNumber(),
      sellPrice: Big(sellPrice).div(marketRate).toNumber(),
    };
  });
}