import React from 'react'
import { service } from '../../machine'

import { Pickaxe } from '../ui/Pickaxe'
import { UpgradeModal } from '../ui/UpgradeModal'

interface Props {
    farmSize: number
    balance: number
}

export const Barn: React.FC<Props> = ({ farmSize, balance }) => {
    const [showModal, setShowModal] = React.useState(false)

    const onUpgrade = () => {
        setShowModal(true)
    }

    const onUpgradeConfirm = () => {
        service.send('UPGRADE')
    }

    return (
        <>

            <div style={{ gridColumn: '18/19', gridRow: '11/12'}}>               
                {
                    farmSize < 17 && (
                        <Pickaxe className="loop" onClick={onUpgrade} />
                    )
                }
                <UpgradeModal onClose={() => setShowModal(false)} isOpen={showModal} farmSize={farmSize} balance={balance} />
            </div>

        </>
    )
}
