import "./Charity.css";

import { useService } from "@xstate/react";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import arrowDown from "../../images/ui/arrow_down.png";
import arrowUp from "../../images/ui/arrow_up.png";
import {
  BlockchainEvent,
  BlockchainState,
  Context,
  service,
} from "../../machine";
import {
  Donation,
  TeamAdress as TeamAdresses,
} from "../../types/contract";
import { Button } from "../ui/Button";
import { Panel } from "../ui/Panel";
import { currency,token,wiki } from '../../../constants';

interface Props {
  onSelect: (donation: Donation) => void;
}

export const Charity: React.FC<Props> = ({ onSelect }) => {
  const [machineState] = useService<
    Context,
    BlockchainEvent,
    BlockchainState
  >(service);

  const [balances, setBalances] = React.useState({
    firstTeamWalletBalance: "",
    secondTeamWalletBalance: "",
  });

  const [donation, setDonation] = React.useState<number>(2.5);

  //Add for donation
  React.useEffect(() => {
    const load = async () => {
      await service.machine.context.blockChain.cacheTotalDonation();
      const donation2 = await service.machine.context.blockChain.totalDonation();
      setDonation(Number(donation2));
    }
    load();
  }, [setDonation]);
  //EndAdd

  React.useEffect(() => {
    if (machineState.context.blockChain.isConnected) {
      const load = async () => {
        const balances =
          await machineState.context.blockChain.getTeamDonationWalletsBalances();
        setBalances(balances);
      };
      load();
    }
  }, [
    machineState.context.blockChain,
    machineState.context.blockChain.isConnected,
  ]);

  const roundToOneDecimal = (number) => Math.round(number * 10) / 10;

  const handleDonationChange = (event) => {
    setDonation(roundToOneDecimal(event.currentTarget.value));
  };

  const incrementDonation = () => {
    setDonation((prevState) => roundToOneDecimal(prevState + 0.1));
  };

  const decrementDonation = () => {
    if (donation === 0.1) {
      setDonation(0.1);
    } else setDonation((prevState) => roundToOneDecimal(prevState - 0.1));
  };

  return (
    <Panel>
      (
        <div id="charity-container">
          <span>Donate to play.</span>
          <span id="donate-description">
            You have to donate to the development team before playing, it
            allows us to invest in the game and pays for the time spent +
            the cost of our infrastructure
          </span>
          <div id="donation-input-container">
            <input
              type="number"
              step="0.1"
              id="donation-input"
              min={donation}
              value={donation}
              onChange={handleDonationChange}
            />
            <div id="arrow-container">
              <img
                className="arrow"
                alt="Step up donation value"
                src={arrowUp}
                onClick={incrementDonation}
              />
              <img
                className="arrow"
                alt="Step down donation value"
                src={arrowDown}
                onClick={decrementDonation}
              />
            </div>
          </div>
          <span id="donate-minimum-description">
            {/* Minimum of ${donation} {currency} <br /> */}
          </span>
          <div id="charities">
            <div>
              <div className="charity">Team support</div>
              <span className="charity-description">
                This donation will be the only source of income for the
                development team, we will not have any advantages over the
                players and we will not be able to influence the course or
                sell as can happen on non-serious projects.
              </span>

              <div className="charity-buttons">
                <Button
                  onClick={() =>
                    onSelect({
                      teamAdress: TeamAdresses.PrincipalTeamAddress,
                      value: donation.toString(),
                    })
                  }
                >
                  Donate & Play
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    </Panel>
  );
};
