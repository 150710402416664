import "./Inventory.css";

import React from "react";

import close from "../../images/ui/close.png";
import leftEdgeInner from "../../images/ui/panel/lt_box_9slice_lc.png";
import rightEdgeInner from "../../images/ui/panel/lt_box_9slice_rc.png";
import topEdgeInner from "../../images/ui/panel/lt_box_9slice_tc.png";
import topLeftInner from "../../images/ui/panel/lt_box_9slice_tl.png";
import topRightInner from "../../images/ui/panel/lt_box_9slice_tr.png";
import treasure from "../../images/ui/treasure.png";
import { ActionableItem } from "../../types/contract";
import { ElementItem } from "../../types/elements";
import { Elements } from "./Elements";

type Tab = "Elements";

interface Props {
  selectedItem: ActionableItem;
  onSelectItem: (item: ActionableItem) => void;
  balance: number;
  land: any[];
  elements: ElementItem[];
  onClose: () => void;
}

export const Items: React.FC<Props> = ({
  selectedItem,
  onSelectItem,
  balance,
  land,
  elements,
  onClose,
}) => {
  const [tab, setTab] = React.useState<Tab>("Elements");
  return (
    <div>
      <img src={close} className="close-icon" onClick={onClose} />
      <div id="inventory-tabs">
        <div className={`inventory-tab ${tab === "Elements" && "active-tab"}`}>
          <img src={treasure} alt="basket" className="tab-icon" />
          <span>Object</span>
          {tab === "Elements" && (
            <>
              <img id="panel-left-edge" src={leftEdgeInner} />
              <img id="panel-right-edge" src={rightEdgeInner} />
              <img id="panel-top-edge" src={topEdgeInner} />
              <img id="panel-top-left" src={topLeftInner} />
              <img id="panel-top-right" src={topRightInner} />
            </>
          )}
        </div>
      </div>
      {tab === "Elements" && (
        <Elements
          selectedItem={selectedItem}
          onSelectItem={onSelectItem}
          elements={elements}
          land={land}
          balance={balance}
        />
      )}
    </div>
  );
};
