import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import watering from "../../images/characters/goblin_watering.gif";
import soil from "../../images/land/soil/soil.png";
import { ActionableItem, Element, Square } from "../../types/contract";
import { ElementItem } from "../../types/elements";
import { UpgradeOverlay } from "../ui/UpgradeModal";
import { Field } from "./Field";

interface Props {
  land: Square[];
  balance: number;
  onHarvest: (landIndex: number) => void;
  onPlant: (landIndex: number) => void;
  selectedItem: ActionableItem;
  elements: ElementItem[];
}

export const SecondZone: React.FC<Props> = ({
  elements,
  land,
  balance,
  onHarvest,
  onPlant,
  selectedItem,
}) => {
  const isUnlocked = land.length > 5;
  const [showWatering, setShowWatering] = React.useState(false);
  const hasRendered = React.useRef(false);
  React.useEffect(() => {
    if (!isUnlocked) {
      return;
    }
    if (land[5].element === Element.None) {
      setShowWatering(false);
    }

    // Only show it on first load
    if (!hasRendered.current && land[5].element !== Element.None) {
      setShowWatering(true);
    }

    if (balance) {
      hasRendered.current = true;
    }
  }, [land, balance]);

  return (
    <>
      <div className="zone-island-title2">Area2</div>
      <div
        id="six-soil"
        className="grass"
        style={{ gridColumn: "21/22", gridRow: "20/21" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[5]}
            onClick={
              land[5].element === Element.None
                ? () => onPlant(5)
                : () => onHarvest(5)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>
      <div
        id="seven-soil"
        className="grass"
        style={{ gridColumn: "22/23", gridRow: "20/21" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[6]}
            onClick={
              land[6].element === Element.None
                ? () => onPlant(6)
                : () => onHarvest(6)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>

      <div
        id="eight-soil"
        className="grass"
        style={{ gridColumn: "21/22", gridRow: "19/20" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[7]}
            onClick={
              land[7].element === Element.None
                ? () => onPlant(7)
                : () => onHarvest(7)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>

      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="first-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "21/22", gridRow: "20/21" }}
          />
        </OverlayTrigger>
      )}
       {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
          id="second-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "22/23", gridRow: "20/21" }}
          />
        </OverlayTrigger>
      )}
       {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="three-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "21/22", gridRow: "19/20" }}
          />
        </OverlayTrigger>
      )}
    </>
  );
};
