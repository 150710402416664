import "./Saving.css";

import React from "react";

import goblin from "../../images/characters/goblin_carry.gif";
import { Panel } from "../ui/Panel";

export const Saving: React.FC = () => (
  <Panel>
    <div id="saving">
      <h4>Saving...</h4>
      <h6>
        worker are working hard to save your farm state to the
        blockchain.
      </h6>

      <div id="mining-animation">
        <img id="crafting-gif" src={goblin} />
      </div>

      <span>Increase the gas price for faster transactions</span>
    </div>
  </Panel>
);
