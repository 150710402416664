import React from "react";

import baldMan from "../../images/characters/bald_man.png";
import chat from "../../images/ui/expression_chat.png";
import { MarketModal } from "../ui/MarketModal";

interface Props {}

export const Market: React.FC<Props> = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <MarketModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />

      <div
        onClick={() => setShowModal(true)}
        id="salesman"
        style={{ gridColumn: "16/17", gridRow: "20/21" }}
      >
        <img className="baldMan" src={baldMan} />
        <img className="chat" src={chat} />
      </div>
    </>
  );
};
