import React from "react";

import InfoMap from "../../images/characters/carrot_man.png";
import chat from "../../images/ui/expression_chat.png";
import eventPoint from "../../images/ui/event-point.png";
import { EventModal } from "../ui/EventModal";
import { MarketModal } from "../ui/MarketModal";

interface Props {}

export const NewMapMan: React.FC<Props> = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <EventModal isOpen={showModal} onClose={() => setShowModal(false)} />

      <div
        onClick={() => setShowModal(true)}
        id="salesman"
        style={{ gridColumn: "26/27", gridRow: "25/26" }}
      >
        <img className="cuisinier" src={InfoMap} />
        <img className="chat2" src={eventPoint} />
      </div>
    </>

  );
};
