import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import soil from "../../images/land/soil/soil.png";
import { ActionableItem, Element, Square } from "../../types/contract";
import { ElementItem } from "../../types/elements";
import { UpgradeOverlay } from "../ui/UpgradeModal";
import { Field } from "./Field";

interface Props {
  land: Square[];
  balance: number;
  onHarvest: (landIndex: number) => void;
  onPlant: (landIndex: number) => void;
  selectedItem: ActionableItem;
  elements: ElementItem[];
}

export const ThirdZone: React.FC<Props> = ({
  elements,
  land,
  balance,
  onHarvest,
  onPlant,
  selectedItem,
}) => {
  const isUnlocked = land.length > 8;

  return (
    <>
    <div className="zone-island-title3">Area3</div>
      <div
        id="nine-soil"
        className="grass"
        style={{ gridColumn: "12/13", gridRow: "15/16" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[8]}
            onClick={
              land[8].element === Element.None
                ? () => onPlant(8)
                : () => onHarvest(8)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>
      <div
        id="ten-soil"
        className="grass"
        style={{ gridColumn: "13/14", gridRow: "15/16" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[9]}
            onClick={
              land[9].element === Element.None
                ? () => onPlant(9)
                : () => onHarvest(9)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>

      <div
        id="eleven-soil"
        className="grass"
        style={{ gridColumn: "14/15", gridRow: "15/16" }}
      >
        {isUnlocked ? (
          <Field
            elements={elements}
            balance={balance}
            selectedItem={selectedItem}
            square={land[10]}
            onClick={
              land[10].element === Element.None
                ? () => onPlant(10)
                : () => onHarvest(10)
            }
          />
        ) : (
          <div className="field">
            <img src={soil} />
          </div>
        )}
      </div>


      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="four-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "12/13", gridRow: "15/16" }}
          />
        </OverlayTrigger>
      )}
      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="five-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "13/14", gridRow: "15/16" }}
          />
        </OverlayTrigger>
      )}
      {!isUnlocked && (
        <OverlayTrigger
          overlay={UpgradeOverlay}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
        >
          <div
            id="six-upgrade-overlay"
            className="upgrade-overlay"
            style={{ gridColumn: "14/15", gridRow: "15/16" }}
          />
        </OverlayTrigger>
      )}


    </>


  );
};
