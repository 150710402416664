import "./Inventory.css";

import React, { useEffect, useState } from "react";

import coin from "../../images/ui/icon.png";
import stopwatch from "../../images/ui/stopwatch.png";
import { ActionableItem } from "../../types/contract";
import { ElementItem, getMarketElements, ElementPriceOptions } from "../../types/elements";
import { secondsToString } from "../../utils/time";
import { Box } from "./Box";
import {token,wiki } from '../../../constants';
import { useService } from "@xstate/react";
import { getMarketRate } from "../../utils/supply";
import { Context, BlockchainEvent, BlockchainState, service } from "../../machine";

interface Props {
  selectedItem: ActionableItem;
  onSelectItem: (item: ActionableItem) => void;
  balance: number;
  land: any[];
  elements: ElementItem[];
}

export const Elements: React.FC<Props> = ({
  selectedItem,
  onSelectItem,
  balance,
  land,
  elements,
}) => {
  const [machineState] = useService<Context, BlockchainEvent, BlockchainState>(service);
  const [totalSupply, setTotalSupply] = useState<number>(1);
  const [elementPrices, setElementPrices] = useState<ElementItem[]>([]);

  useEffect(() => {
    const load = async () => {
      const supply = await service.machine.context.blockChain.totalSupply();
      setTotalSupply(supply);

      await service.machine.context.blockChain.cacheElementGains();
      const elementGains = service.machine.context.blockChain.getCachedElementGains();

      const marketRate = getMarketRate(supply);
      const prices = getMarketElements({ marketRate, elementGains });
      setElementPrices(prices);
    };

    load();
  }, []);

  // Si selectedItem est défini, mettez à jour l'objet sélectionné
  let element = selectedItem ? elementPrices.find((f) => f.element === selectedItem.element) : null;

  // // Grab the market price
  // if (element) {
  //   element = elementPrices.find((f) => f.element === element.element);
  //   console.log(element);
  // }
  return (
    <div id="crafting">
      <div id="crafting-left">
        <div id="crafting-items">
          {elementPrices.map((el) =>
            el.landRequired > land.length ? (
              <Box key={el.element} disabled />
            ) : (
              <Box key={el.element}
                isSelected={selectedItem && selectedItem.element === el.element}
                onClick={() => onSelectItem(el)}
              >
                <img src={el.image} className="box-item" />
              </Box>
            )
          )}
        </div>
        <a href={wiki} target="_blank">
          <h3 className="current-price-supply-demand">Read more</h3>
        </a>
      </div>
      <div id="recipe">
        {element && (
          <>
            <span id="recipe-title">{element.name}</span>
            <div id="crafting-item">
              <img src={element.image} />
            </div>

            <div id="ingredients">
              <div className="ingredient">
                <div>
                  <img className="ingredient-image" src={stopwatch} />
                  <span className="ingredient-count">Time</span>
                </div>
                <span className="ingredient-text">
                  {secondsToString(element.harvestMinutes * 60)}
                </span>
              </div>
              <div >
                <img className="ingredient-image" src={coin} />
                <span>Prices</span>
              </div>

              <div className="ingredient">
                <div>
                  <span className="ingredient-count">Deposit</span>
                </div>
                <span className="ingredient-text">{`${element.buyPrice} $${token}`}</span>
              </div>
              <div className="ingredient">
                <div>
                  <span className="ingredient-count">Harvest</span>
                </div>
                <span className="ingredient-text">{`${element.sellPrice} $${token} `}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
