import React from "react";

import cuisinier from "../../images/characters/man.png";
import cuisinier2 from "../../images/characters/carrot_man.png";
import cuisinier3 from "../../images/characters/girl.png";
import gobdownCheveu from "../../images/characters/gobdownCheveu.png";
import gobdowncheveu2 from "../../images/characters/gobdowncheveu2.png";
import gobdown from "../../images/characters/gobdown.png";
import gobdownRevert from "../../images/characters/gobdownRevert.png";
import gobelin from "../../images/characters/gobelin.png";
import arrosoir from "../../images/characters/Arrosoir.gif";
import gauche from "../../images/characters/PersoGauche.png";
import happy from "../../images/characters/Happy.png";
import vieux from "../../images/characters/Vieux.png";
import cow from "../../images/characters/cow.gif";
import cow2 from "../../images/characters/cow2.gif";
import creuse from "../../images/characters/creuse.gif";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const Decoration: React.FC<Props> = () => {
  
  return (
    <>
      {/* <div        
          style={{ gridColumn: "7/9", gridRow: "10/11" }}
        >
          <img className="cuisinier" src={cuisinier} alt="Ok" />
      </div> */}

      {/* <div        
          style={{ gridColumn: "26/27", gridRow: "25/26" }}
        >
          <img className="cuisinier2" src={cuisinier2} />
      </div> */}

      
      <div id="deco"        
          style={{ gridColumn: "6/7", gridRow: "10/11" }}
        >
          <img className="gauche" src={gauche} />
      </div>

      <div id="deco"        
          style={{ gridColumn: "9/10", gridRow: "23/24" }}
        >
          <img className="gauche" src={happy} />
      </div>

      <div id="deco"         
          style={{ gridColumn: "18/19", gridRow: "13/14" }}
        >
          <img className="gauche" src={vieux} />
      </div>

      <div id="arrosoir"        
          style={{ gridColumn: "25/26", gridRow: "19/20" }}
        >
          <img className="arrosoir" src={arrosoir} />
      </div>


      <div id="deco"        
          style={{ gridColumn: "12/13", gridRow: "17/18" }}
        >
          <img className="cuisinier3" src={cuisinier3} />
      </div>

      <div id="cow"       
          style={{ gridColumn: "8/9", gridRow: "18/19" }}
        >
          <img className="cow" src={cow} />
      </div>

      <div id="deco"        
          style={{ gridColumn: "26/27", gridRow: "4/5" }}
        >
          <img className="cow2" src={cow2} />
      </div>
      <div  id="cuisinier2"       
          style={{ gridColumn: "18/19", gridRow: "15/16" }}
        >
          <img className="cuisinier2" src={creuse} />
      </div>



      {/* <div        
          style={{ gridColumn: "18/20", gridRow: "10/11" }}
        >
          <img className="cuisinier" src={cuisinier} />
      </div> */}

      {/* <div        
          style={{ gridColumn: "21/23", gridRow: "10/11" }}
        >
          <img className="cuisinier2" src={cuisinier2} />
      </div> */}

      <div  id="deco"        
          style={{ gridColumn: "6/7", gridRow: "4/5" }}
        >
          <img className="gobdown" src={gobdown} />
      </div>

      {/* <div        
          style={{ gridColumn: "17/18", gridRow: "19/20" }}
        >
          <img className="gobdown2" src={gobdown} />
      </div> */}

      {/* <div        
          style={{ gridColumn: "18/19", gridRow: "19/20" }}
        >
          <img className="gobdownCheveu" src={gobdownCheveu} />
      </div> */}

      {/* <div        
          style={{ gridColumn: "8/9", gridRow: "19/20" }}
        >
          <img className="gobdown2" src={gobdownRevert} />
      </div> */}
{/* 
      <div        
          style={{ gridColumn: "24/25", gridRow: "28/29" }}
        >
          <img className="gobelin" src={gobelin} />
      </div> */}

      {/* <div        
          style={{ gridColumn: "14/15", gridRow: "16/17" }}
        >
          <img className="gobelin" src={gobelin} />
      </div> */}

      {/* <div        
          style={{ gridColumn: "26/27", gridRow: "19/20" }}
        >
          <img className="gobdownCheveu" src={gobdowncheveu2} />
      </div> */}
   </>
  );
};